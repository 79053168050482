import { base } from '@theme-ui/presets';

export default {
  ...base,
  colors: {
    text: '#414042',
    background: '#F3F3F3',
    primary: '#1463C9',
    primaryDark: '#104ABF',
    primaryLight: '#1B76CE',
    primaryShade: 'rgba(20, 99, 201, .05)',
    secondary: '#267A26',
    secondaryDark: '#22742B',
    secondaryLight: '#34812E',
    accent: '#BA9B65',
    gray: '#707070',
    lightGray: '	#DCDCDC',
    highlight: '#F3F3F3',
    muted: '#707070',
    mutedTransparent: 'rgba(44, 44, 44, 0.1)',
  },
  breakpoints: ['576px', '768px', '992px', '1200px'],
  space: [
    '0rem',
    '.25rem',
    '.5rem',
    '.75rem',
    '1rem',
    '1.5rem',
    '2rem',
    '2.5rem',
    '3rem',
    '4rem',
    '8rem',
    '16rem',
  ],
  fonts: {
    body: '"proxima-nova", sans-serif',
    heading: 'inherit',
  },
  fontSizes: [12, 14, 16, 20, 24, 32, 48, 64],
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  forms: {
    label: {
      fontSize: 1,
      fontWeight: 'bold',
    },
    input: {
      borderColor: 'text',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      marginBottom: 2,
      '&::placeholder': {
        fontFamily: 'Arial',
        color: 'gray',
      },
      '&:focus': {
        borderColor: 'primary',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        boxShadow: (t) => `0 0 5px ${t.colors.primary}`,
        outline: 'none',
      },
    },
    select: {
      borderColor: 'text',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      marginBottom: 2,
      '&:focus': {
        borderColor: 'primary',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        boxShadow: (t) => `0 0 5px ${t.colors.primary}`,
        outline: 'none',
      },
    },
    textarea: {
      borderColor: 'text',
      backgroundColor: 'rgba(255, 255, 255, 0.5)',
      marginBottom: 2,
      '&:focus': {
        borderColor: 'primary',
        backgroundColor: 'rgba(255, 255, 255, 0.8)',
        boxShadow: (t) => `0 0 5px ${t.colors.primary}`,
        outline: 'none',
      },
      '&::placeholder': {
        fontFamily: 'Arial',
        color: 'gray',
      },
    },
    slider: {
      bg: 'muted',
    },
  },
  buttons: {
    primary: {
      backgroundColor: 'primary',
      '&:hover': {
        backgroundColor: 'primaryDark',
      },
      color: 'background',
    },
    secondary: {
      backgroundColor: 'secondary',
      '&:hover': {
        backgroundColor: 'secondaryDark',
      },
      color: 'background',
    },
  },
  lineHeights: {
    body: 1.5,
    heading: 1.125,
  },
  letterSpacings: {
    body: 'normal',
    caps: '0.2em',
  },
  link: {
    fontSize: 2,
    color: 'primary',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  reverseLink: {
    fontSize: 2,
    color: 'background',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  text: {
    heading: {
      fontFamily: 'heading',
      lineHeight: 'heading',
      fontWeight: 'heading',
    },
    body: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
    },
    caps: {
      textTransform: 'uppercase',
      letterSpacing: '0.1em',
    },
    primary: {
      color: 'primary',
    },
    reverse: {
      color: 'background',
    },
    underline: {
      textDecoration: 'underline',
    },
  },
  menuWrapper: {
    mobileClosed: {
      right: ['-250px', '-250px', 'initial'],
      display: ['none', 'none', 'block'],
      maxWidth: ['250px', '250px', '100%'],
      width: ['80%', '80%', '100%'],
      minHeight: ['100vh', '100vh', 'inherit'],
      position: ['absolute', 'absolute', 'relative'],
      backgroundColor: ['background', 'background', 'primary'],
    },
  },
  styles: {
    root: {
      fontFamily: 'body',
      lineHeight: 'body',
      fontWeight: 'body',
      fontSize: 2,
    },
    p: {
      variant: 'text.body',
      fontSize: 2,
      color: 'text',
      my: 4,
    },
    a: {
      variant: 'link',
    },
    h1: {
      variant: 'text.heading',
      fontSize: 5,
      lineHeight: 'heading',
    },
    h2: {
      variant: 'text.heading',
      fontSize: 4,
      lineHeight: 'heading',
    },
    h3: {
      variant: 'text.heading',
      fontSize: 3,
      lineHeight: 'heading',
    },
    h4: {
      variant: 'text.heading',
      fontSize: 2,
      lineHeight: 'body',
    },
    h5: {
      variant: 'text.heading',
      fontSize: 2,
      lineHeight: 'body',
    },
    h6: {
      variant: 'text.heading',
      fontSize: 2,
      lineHeight: 'body',
    },
    pre: {
      fontFamily: 'monospace',
      overflowX: 'auto',
      code: {
        color: 'inherit',
      },
    },
    code: {
      fontFamily: 'monospace',
      fontSize: 'inherit',
    },
    table: {
      width: '100%',
      borderCollapse: 'separate',
      borderSpacing: 0,
    },
    th: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    td: {
      textAlign: 'left',
      borderBottomStyle: 'solid',
    },
    ul: {
      my: 2,
    },
    li: {
      my: 2,
    },
  },
};
